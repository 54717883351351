import React from 'react';
import { FaqWidget } from 'components/FaqWidget';
import { FaqMain, Row, Title, Subtitle, Box, Column } from './styles.js';
import useTranslation from 'hooks/useTranslation.js';
import FAQLeft from 'assets/FAQLeft.png';
import FAQRight from 'assets/FAQRight.png';

export const FAQ = ({ toggle, handleToggle }) => {
  const { t } = useTranslation();
  return (
    <FaqMain>
      <img className="FAQLeft" alt="" src={FAQLeft} />
      <img className="FAQRight" alt="" src={FAQRight} />
      <Row className="wrap-reverse">
        <Title>{t('faq.title')}</Title>
        <Subtitle>{t('faq.subtitle')}</Subtitle>
      </Row>
      <Box>
        <Column>
          <FaqWidget
            toggle={toggle}
            idx={0}
            handleToggle={handleToggle}
            title={t('faq.item1.value')}
            subtitle={t('faq.item1.description')}
          />
          <FaqWidget
            toggle={toggle}
            idx={1}
            handleToggle={handleToggle}
            title={t('faq.item2.value')}
            subtitle={t('faq.item2.description')}
          />
          <FaqWidget
            toggle={toggle}
            idx={2}
            handleToggle={handleToggle}
            title={t('faq.item3.value')}
            subtitle={t('faq.item3.description')}
          />
          <FaqWidget
            toggle={toggle}
            idx={3}
            handleToggle={handleToggle}
            title={t('faq.item4.value')}
            subtitle={t('faq.item4.description')}
          />
        </Column>
        <Column>
          <FaqWidget
            toggle={toggle}
            idx={4}
            handleToggle={handleToggle}
            title={t('faq.item5.value')}
            subtitle={t('faq.item5.description')}
          />
          <FaqWidget
            toggle={toggle}
            idx={5}
            handleToggle={handleToggle}
            title={t('faq.item6.value')}
            subtitle={t('faq.item6.description')}
          />
          <FaqWidget
            toggle={toggle}
            idx={6}
            handleToggle={handleToggle}
            title={t('faq.item7.value')}
            subtitle={t('faq.item7.description')}
          />
          <FaqWidget
            toggle={toggle}
            idx={7}
            handleToggle={handleToggle}
            title={t('faq.item8.value')}
            subtitle={t('faq.item8.description')}
          />
          <FaqWidget
            toggle={toggle}
            idx={8}
            handleToggle={handleToggle}
            title={t('faq.item9.value')}
            subtitle={t('faq.item9.description')}
          />
        </Column>
      </Box>
    </FaqMain>
  );
};
