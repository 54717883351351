import React from 'react';
import { TRUButton } from 'components/TRUButton';
import {
  WelcomeBox,
  WelcomeContentRow,
  WelcomeContentColumn,
  Headline,
  Headline1,
  Subline,
  Padding,
  BottomLine,
  CurvedArrow,
  Spacer,
  WelcomeMedia,
  VideoPic,
  VideoPicMobile,
  OpacPlayRing,
  SolidPlayRing,
  Play,
  PuzzlePieces,
  Truline,
  TermlinePara,
  Termline,
} from './styles.js';
//assets
import bookIcon from 'assets/book-icon.webp';
import arrowIcon from 'assets/arrow-icon.webp';
import checkIcon from 'assets/check-icon.webp';
import vidpic from 'assets/HeaderCircleImages.png';
import vidpicmobileView from 'assets/HeaderCircleImagesMobile.png';
import Arrow from 'assets/whiteRightArrow.svg';
import puzzle from 'assets/puzzlepieces.svg';
import curved from 'assets/curvedArrow.svg';
import play from 'assets/play.svg';
import useTranslation from 'hooks/useTranslation.js';
import { useResizeDetector } from 'react-resize-detector';
import { isMobile } from 'react-device-detect';
import './index.css';

export const Welcome = ({ videoToggle, handleVideo, handleClick, truTermsLink }) => {
  const { width, ref } = useResizeDetector();
  const Mobile = isMobile && width < 600;
  const { t } = useTranslation();
  return (
    <div className="header-d">
      <WelcomeBox ref={ref}>
        <WelcomeContentRow>
          <WelcomeContentColumn>
            <Headline>
            <div className='banner-text'>
            <b> <p className='titleText'>{t('welcome.title')} </p>
            <Headline1><p><span  >{t('welcome.title11')}</span></p></Headline1> 
            <p>{t('welcome.title12')}</p>
            </b>
            </div>
            </Headline>
            <div className='banner-list'>
            <div className='d-flex'>
            <img src={bookIcon} className='banner-icons'/>  
            <Truline>{t('welcome.subtitle3')}</Truline>
            </div>

            <div className='d-flex'>
            <img src={arrowIcon} className='banner-icons'/>  
            <TermlinePara>
              {t('welcome.terms1')}
              <Termline target="_blank" href={truTermsLink}>
                {t('welcome.terms2')}
              </Termline>
              {t('welcome.terms3')}
            </TermlinePara>
            </div>

            <div className='d-flex'>
            <img src={checkIcon} className='banner-icons'/> 
            <Subline>{t('welcome.subtitle1')}</Subline>
            </div>
            </div>
            <div className="Tru-btn">
            <TRUButton
              iconColor="#19C1F3"
              font={3}
              boxColor="white"
              textColor="#1C2253"
              handleClick={handleClick}
              icon={Arrow}
            />
            <BottomLine>{t('welcome.subtitle2')}</BottomLine>
            </div>
            <CurvedArrow className="curved-arrow" src={curved} />
          </WelcomeContentColumn>
          <Spacer />
          <WelcomeMedia>
            {!Mobile && <VideoPic src={vidpic} />}
            {Mobile && <VideoPicMobile src={vidpicmobileView} />}
            <Padding onClick={() => handleVideo(videoToggle)}>
              <OpacPlayRing>
                <SolidPlayRing>
                  <Play src={play} />
                </SolidPlayRing>
              </OpacPlayRing>
            </Padding>
            <PuzzlePieces src={puzzle} />
          </WelcomeMedia>
        </WelcomeContentRow>
      </WelcomeBox>
    </div>
  );
};
