import React, { useState } from 'react';
import StyledTips from './styles';
import Container from 'react-bootstrap/Container';
import ReactPlayer from 'react-player/youtube';
import Carousel from 'react-multi-carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import tipsBanner from '../../assets/tips-banner.png';
import tipsVids from '../../assets/json/tips.json';
import redPlay from '../../assets/red-play-arrow.svg';
import { StyledModal } from 'Layout/FormModal/styles';
import Video from 'Layout/Testimonials/Video';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 991 },
    items: 3,
    partialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: { max: 991, min: 600 },
    items: 2,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    partialVisibilityGutter: 0,
  },
};
const ButtonGroup = ({ next, previous }) => {
  return (
    <div className="carousel-button-group position-relative d-flex justify-content-between align-items-center">
      <div className="previousButton" onClick={() => previous()}></div>
      <div className="nextButton" onClick={() => next()}></div>
    </div>
  );
};
const Tips = () => {
  const [play, setPlay] = useState(false);
  const [url, setUrl] = useState('');
  const videoToggle = (play, url) => {
    setPlay(!play);
    setUrl(url);
  };

  return (
    <StyledTips>
      <Container className="text-center">
        <Image fluid="md" className="mb-3 px-5 px-lg-0 helpful-tips-img" src={tipsBanner} />
        <Row className="justify-content-center  px-0 px-md-5">
          <Col className="text-center">
            {tipsVids ? (
              <Carousel
                responsive={responsive}
                ssr
                showDots={false}
                minimumTouchDrag={80}
                slidesToSlide={1}
                partialVisibile={true}
                infinite={true}
                containerClass="container-with-dots"
                itemClass="image-item"
                className="text-center"
                autoPlay={true}
                autoPlaySpeed={3000}
                arrows={false}
                renderButtonGroupOutside={true}
                customButtonGroup={<ButtonGroup />}
              >
                {tipsVids.map((item, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => videoToggle(play, item.url)}
                      className="img-hover d-flex justify-content-center  align-items-center"
                    >
                      <Image className="redPlay" src={redPlay} />
                      <img
                        className="img-thumbnail carousel-image"
                        draggable={false}
                        style={{ position: 'relative' }}
                        src={item.thumb}
                        alt={''}
                      />
                    </div>
                  );
                })}
              </Carousel>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </Col>
        </Row>
      </Container>
      <StyledModal show={play}>
        <Video onClose={() => videoToggle(play, '')} url={url} />
      </StyledModal>
    </StyledTips>
  );
};
export default Tips;
