import React from 'react';
import {
  OfferMain,
  Row,
  LeftColumn,
  Dots,
  TextBox,
  CoupleBack,
  RowText,
  BoldText,
  Text,
  CoupleFront,
  RightColumn,
  Box,
  Title,
  Subtitle,
  List,
  ListItem,
  TruBox,
  TRUTermButton,
} from './styles.js';
import back from 'assets/coupleBack.png';
import MobileOfferLine from 'assets/MobileOfferLine.png';
import front from 'assets/coupleFront.png';
import dots from 'assets/squareDots.svg';
import { TRUButton } from 'components/TRUButton';
import Arrow from 'assets/blueRightArrow.svg';
import useTranslation from 'hooks/useTranslation.js';

export const Offer = ({ offerAni, years, handleClick, truTermsLink }) => {
  const { t } = useTranslation();
  return (
    <OfferMain>
      <img className="mobileOfferDesign" alt="" src={MobileOfferLine} />
      <Row>
        <LeftColumn>
          <CoupleBack src={back} />
          <Dots src={dots} />
          <TextBox id="offer" className={offerAni ? 'animate__animated animate__tada' : ''}>
            <RowText>
              <BoldText>{years()}</BoldText>
              <Text>{t('offer.text')}</Text>
            </RowText>
          </TextBox>
        </LeftColumn>
        <CoupleFront src={front} />
      </Row>

      <RightColumn>
        <Box>
          <Title>
            {t('offer.title')} <Subtitle>{t('offer.subtitle')}</Subtitle>
          </Title>
          <Box>
            <List>
              {Object.values(t('offer.items')).map((el, index) => (
                <ListItem key={index}>{el}</ListItem>
              ))}
            </List>
            <TruBox>
              <TRUButton
                boxColor="#19C1F3"
                iconColor="white"
                textColor="white"
                handleClick={handleClick}
                icon={Arrow}
              />
              <TRUTermButton target="_blank" href={truTermsLink} style={{ textDecoration: 'underline' }}>
                {t('footer.part9')}
              </TRUTermButton>
            </TruBox>
          </Box>
        </Box>
      </RightColumn>
    </OfferMain>
  );
};
