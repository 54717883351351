import React from 'react';
import { TRUButton } from 'components/TRUButton';
import {
  StepsMain,
  Column,
  Title,
  Row,
  ThinCircle,
  Circle,
  IconCircle,
  Icon,
  BoldText,
  SubText,
  TruBox,
} from './styles.js';
//assets
import clock from 'assets/clock.svg';
import badge from 'assets/badge.svg';
import house from 'assets/house.svg';
import payment from 'assets/payment.svg';
import DashedCurveBottom from 'assets/DashedCurveBottom.png';
import DashedCurveTop from 'assets/DashedCurveTop.png';
import Arrow from 'assets/blueRightArrow.svg';
import useTranslation from 'hooks/useTranslation.js';
import StepsLeft from 'assets/StepsLeft.png';
import StepsRight from 'assets/StepsRight.png';

export const Steps = ({ handleClick }) => {
  const { t } = useTranslation();
  return (
    <StepsMain>
      <img className="StepsLeft" alt="" src={StepsLeft} />
      <img className="StepsRight" alt="" src={StepsRight} />
      <Column>
        <Title>
          {t('steps.title')}
          <span style={{ color: '#19c1f3' }}>{t('steps.subtitle')}</span>
        </Title>
      </Column>

      <Row>
        <Column>
          <ThinCircle color="#19C1F3">
            <Circle color="white">
              <Circle color="#E5F9FF">
                <IconCircle color="#19C1F3">
                  <Icon color="#19C1F3" src={clock} />
                </IconCircle>
              </Circle>
            </Circle>
          </ThinCircle>
          <BoldText>
            {t('steps.item1')}
            <br /> {t('steps.item2')}
            <br /> {t('steps.item3')}
          </BoldText>
          <SubText>{t('steps.item4')}</SubText>
        </Column>
        <img className="dash-one" alt="" src={DashedCurveBottom} />
        <Column>
          <ThinCircle color="#EB5757">
            <Circle color="white">
              <Circle color="#FAE1E1">
                <IconCircle color="#EB5757">
                  <Icon color="#EB5757" src={badge} />
                </IconCircle>
              </Circle>
            </Circle>
          </ThinCircle>
          <BoldText>
            {t('steps.item5')}
            <br /> {t('steps.item6')}
            <br /> {t('steps.item7')}
          </BoldText>
          <SubText>{t('steps.item8')}</SubText>
        </Column>
        <img className="dash-two" alt="" src={DashedCurveTop} />
        <Column>
          <ThinCircle color="#2F80ED">
            <Circle color="white">
              <Circle color="#E1ECFA">
                <IconCircle color="#2F80ED">
                  <Icon color="#2F80ED" src={house} />
                </IconCircle>
              </Circle>
            </Circle>
          </ThinCircle>
          <BoldText>
            {t('steps.item9')}
            <br /> {t('steps.item10')}
          </BoldText>
          <SubText>{t('steps.item11')}</SubText>
        </Column>
        <img className="dash-three" alt="" src={DashedCurveBottom} />
        <Column>
          <ThinCircle color="#9B51E0">
            <Circle color="white">
              <Circle color="#EEE1FA">
                <IconCircle color="#9B51E0">
                  <Icon color="#9B51E0" src={payment} />
                </IconCircle>
              </Circle>
            </Circle>
          </ThinCircle>
          <BoldText>
            {t('steps.item12')}
            <br /> {t('steps.item13')}
            <br /> {t('steps.item14')}
          </BoldText>
          <SubText>{t('steps.item15')}</SubText>
        </Column>
      </Row>
      <TruBox>
        <TRUButton
          textColor="white"
          boxColor="#19C1F3"
          iconColor="white"
          handleClick={handleClick}
          icon={Arrow}
        />
      </TruBox>
    </StepsMain>
  );
};
