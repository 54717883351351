import styled from 'styled-components';
import background from 'assets/infoBackground.png';
import backgroundMobile from 'assets/InfoBGMobile.svg';

export const InfoMain = styled.div`
  width: 100%;
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  padding: 2vw 0vw 2vw 0rem;
  @media only screen and (min-width: 601px) {
    background: url(${background}) no-repeat center center;
    .InfoLeft {
      position: absolute;
      left: 0vw;
      top: 0vw;
      width: 3vw;
    }
    .InfoRight {
      position: absolute;
      z-index: -1;
      right: 0vw;
    }
    .secondsInfo {
      min-width: 175px;
    }
    .decisionInfo {
      min-width: 190px;
    }
  }
  @media only screen and (max-width: 600px) {
    background: url(${backgroundMobile});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .InfoLeft {
      display: none;
    }
    .InfoRight {
      display: none;
    }
  }
`;
export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0rem 0rem 0rem;
  width: 100%;
  @media only screen and (min-width: 600px) {
    width: calc(75%);
    padding: 0rem;
  }
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  @media (min-width: 600px) {
    align-items: space-around;
    justify-content: flex-start;
    flex-direction: column;
  }
`;
export const AniColumn = styled(Column)``;
export const SmallTaglineBox = styled(Column)`
  flex-direction: column;
  width: calc(100% - 2vw);
  height: 100%;
  padding: 1vw 0;
  @media (min-width: 600px) {
    flex-direction: row;
    padding: 0;
    justify-content: center;
    align-items: flex-start;
  }
`;
export const TaglineColumn = styled(Column)`
  width: 100%;
`;
export const OtherTaglineBox = styled(SmallTaglineBox)`
  padding: 0;

  @media (min-width: 600px) {
    padding: 0;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
export const TaglineBox = styled(Column)`
  flex-direction: row;
  justify-content: space-between;
`;

export const BlueTitle = styled.h4`
  letter-spacing: -0.01em;
  line-height: 125%;
  color: #1c2253;
  font-size: 6.5vw;
  padding: 0;
  width: calc(100%);
  text-align: center;
  white-space: nowrap;
  @media (min-width: 600px) {
    text-align: left;
    font-size: 2.75vw;
    padding-right: 2vw;
    width: calc(100% - 2vw);
  }
  @media (min-width: 600px) {
    font-size: 2.5vw;
  }
`;
export const AquaTitle = styled(BlueTitle)`
  color: #19c1f3;
`;

export const SmallText = styled.p`
  font-size: 4.5vw;
  line-height: 162%;
  width: calc(100% - 2vw);
  font-weight: 300;
  text-align: center;
  padding: 2vw;
  @media (min-width: 600px) {
    text-align: left;
    font-size: 1.75vw;
    font-weight: 400;
    padding: 0vw 0vw 0vw 1vw;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 1.5vw;
    line-height: 178%;
  }
`;

export const BlueSubtitle = styled(BlueTitle)`
  font-size: 13.5px;
  text-align: center;
  padding: 0vw;
  @media (min-width: 600px) {
    padding: 0.5vw 0vw;
    font-size: 1.5vw;
  }
  @media (min-width: 1000px) {
    font-size: 1.3vw;
  }
`;
export const AquaSubtitle = styled(AquaTitle)`
  font-size: 16px;
  text-align: center;
  padding: 0vw;
  font-weight: 900;
  @media (min-width: 600px) {
    font-size: 2vw;
  }
  @media (min-width: 1000px) {
    font-size: 1.5vw;
  }
`;
export const Line = styled.img`
  transform: rotate(90deg);
  padding: 0vw;
  height: 20vw;
  width: 1vw;
  @media (min-width: 600px) {
    transform: rotate(0deg);
    height: 5vw;
  }
  @media (min-width: 600px) {
    height: 8vw;
  }
`;
export const FadingLine = styled(Line)`
  display: none;
  @media (min-width: 600px) {
    display: flex;
  }
`;
