import styled, { keyframes } from 'styled-components';

export const Main = styled.section`
  width: calc(100%);
  height: 100%;
  display: flex;
  padding: 3vw 0vw 0vw 0vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const VideoMain = styled.section`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.videoToggle ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 10;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: rgb(88, 88, 88, 0.8);
  &::::-webkit-scrollbar {
    display: none;
  }
`;
export const VideoBox = styled.div`
  padding: 1vw;
  display: flex;
  justify-content: flex-end;
  align-item: flex-start;
  background-color: rgb(45, 45, 45, 0.8);
  width: 90%;
  height: 50vw;
  @media (min-width: 600px) {
    max-width: 48rem;
    width: 90%;
    max-height: 27.5rem;
    height: 50.5vw;
  }
  @media (min-width: 1000px) {
    max-width: 61.5vw;
    max-height: 35.5vw;
    margin-top: 9rem;
  }
`;
export const fluctuate = keyframes`
0%{transform:scale(0.95);}
50%{transform:scale(1.05);}
100%{transform:scale(0.95);}
`;
export const Close = styled.img`
  padding: 1vw 1vw 1vw 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  background: white;
  margin-top: -3vw;
  margin-right: -3vw;
  z-index: 10;
  &:hover {
    background: rgb(25, 193, 243);
    z-index: 10;
    animation: ${fluctuate} 1.3s infinite ease-in-out;
  }
`;
