import React, { useState, useEffect } from 'react';
import { ContactPhone } from './ContactPhone';
import UserWayAccessibilityMenu from "./UserWayAccessibilityMenu/index";
import { StyledHeader, Spacer, TRUApprovalLogo } from './styles.js';
import { Navbar, Container } from 'react-bootstrap';
import truLogo from 'assets/tru-logo.6574879e copy 1.png';
import calling from 'assets/CallingWhite.svg';
import './index.css';
import './userwayWidget.css';


export const Header = ({ loanOfficerData }) => {
  const [navbar, setNavbar] = useState(false);

   const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, []);

  return (
    <Container>
      <Navbar
        expand="lg"
        fixed="top"
        bg="rgb(25, 193, 243)"
        variant="rgb(25, 193, 243)"
        className={navbar ? 'navbar active' : 'navbar'}
      >
        <StyledHeader>
          <Spacer />
          <div className="d-flex">
          <TRUApprovalLogo src={truLogo} />
          <UserWayAccessibilityMenu color="white" width={30} height={30} />
          </div>
          <Spacer />
          <Spacer />
          <Spacer />
          <Spacer />
          <ContactPhone icon={calling} loanOfficerData={loanOfficerData}   />
          <Spacer />
        </StyledHeader>
      </Navbar>
    </Container>
  );
};
