import { get } from 'utils';
import en from 'locales/en.json';
import es from 'locales/es.json';

const useTranslation = () => {
  const t = (path) => {
    const obj = { en: en, es: es };
    const lang = process.env.REACT_APP_LANG || 'en';
    return get(obj[lang], path, '');
  };
  return { t };
};

export default useTranslation;
