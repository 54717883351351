import React from 'react';
import styled, { keyframes } from 'styled-components';
import parser from 'html-react-parser';
import useTranslation from 'hooks/useTranslation.js';

export const TRUButton = ({ icon, handleClick, textColor, boxColor, iconColor }) => {
  const { t } = useTranslation();
  return (
    <Box textColor={textColor} boxColor={boxColor} onClick={handleClick}>
      <Text textColor={textColor}>{parser(t('trubutton'))}</Text>
      <Icon iconColor={iconColor} src={icon} />
    </Box>
  );
};

export const enter = (textColor, boxColor) => keyframes`
0%{color:${
  textColor === 'white' && boxColor === '#19C1F3'
    ? 'white'
    : textColor === 'white'
    ? 'white'
    : '#1C2253'
}; background:${boxColor === 'white' ? 'white' : boxColor === '#19C1F3' ? '#19C1F3' : '#1c2253'};}
100%{color:${
  textColor === 'white' && boxColor === '#19C1F3'
    ? 'white'
    : textColor === 'white'
    ? '#1C2253'
    : 'white'
};background:${boxColor === 'white' ? '#1c2253' : boxColor === '#19C1F3' ? '#1c2253' : 'white'};}
`;

export const leave = (textColor, boxColor) => keyframes`
0%{color:${
  textColor === 'white' && boxColor === '#19C1F3'
    ? 'white'
    : textColor === 'white'
    ? '#1C2253'
    : 'white'
};background:${boxColor === 'white' || boxColor === '#19C1F3' ? '#1c2253' : 'white'};}
100%{color:${textColor === 'white' ? 'white' : '#1C2253'};background:${
  boxColor === 'white' ? 'white' : boxColor === '#19C1F3' ? '#19C1F3' : '#1c2253'
};}
`;

export const Icon = styled.img`
  padding: 0.5vw;
  background: ${(props) => props.iconColor};
  border-radius: 50%;
  height: 8vw;
  width: 8vw;
  padding: 2vw;
  @media only screen and (min-width: 600px) {
    height: 4vw;
    width: 4vw;
    padding: 1vw;
  }
  @media only screen and (min-width: 1000px) {
    height: 3vw;
    width: 3vw;
    padding: 1vw;
  }
`;

export const Text = styled.p`
  line-height: 189%;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  margin: 0;
  padding: 0vw 2vw;
  font-size: 4.275vw;
  font-weight: 500;
  @media (min-width: 600px) {
    padding: 0vw 2vw 0vw 2vw;
    font-size: 1.5vw;
  }
  @media (min-width: 1000px) {
    font-size: 1.25vw;
  }
`;
export const Box = styled.span`
  background: ${(props) => props.boxColor};
  box-shadow: 0px 9px 60px rgba(28, 191, 239, 0.2);
  border-radius: 100px;
  padding: 1vw;
  display: flex;
  z-index: 3;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  color: ${({ textColor }) => textColor};
    linear 0s 1;
    transition: 0.4s;
  &:hover {
    color:${(props) =>
      props.textColor === 'white' && props.boxColor === '#19C1F3'
        ? 'white'
        : props.textColor === 'white'
        ? '#1C2253'
        : 'white'};
        background:${({ boxColor }) =>
          boxColor === 'white' ? '#1c2253' : boxColor === '#19C1F3' ? '#1c2253' : 'white'};
    }
  @media only screen and (min-width: 600px) {
    padding: 0.5vw;
  }
`;
