import styled, { keyframes } from 'styled-components';
import backgroundMobile from 'assets/StepsBGMobile.svg';

export const shake = keyframes`
0%{transform:rotate(-25deg);margin-left:-2px;}
49%{transform:rotate(-25deg);margin-left:-2px;}
50%{transform:rotate(25deg);margin-left:2px;}
100%{transform:rotate(25deg);margin-left:2px;}
`;
export const StepsMain = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100%);
  padding: 0vw 0vw 1vw 0vw;
  @media (min-width: 600px) {
    padding: 2vw 0vw 2vw 0vw;
  }
  @media only screen and (min-width: 601px) {
    .StepsLeft {
      position: absolute;
      left: 0vw;
      height: 40vw;
    }
    .StepsRight {
      position: absolute;
      right: 0vw;
      width: 10vw;
    }
  }
  @media only screen and (max-width: 600px) {
    background: url(${backgroundMobile});
    aspect-ratio: auto 375/1736;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 10vw;
    .StepsLeft {
      display: none;
    }
    .StepsRight {
      display: none;
    }
  }
`;

export const TruBox = styled.div`
  padding: 1.5vw;
  padding-bottom: 5vw;
  @media (min-width: 600px) {
    padding: 0;
  }
`;
export const Icon = styled.img`
  background-color: ${(props) => props.color};
  height: 12vw;
  width: 12vw;
  padding: 2.5vw;

  @media (min-width: 600px) {
    height: 4vw;
    padding: 0.25vw;
    width: 4vw;
  }
  @media (min-width: 1000px) {
    height: 3vw;
    padding: 0vw;
    width: 3vw;
  }
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vw 0 0 0;
  margin: 0vw 1vw;
  width: calc(100%);
  &:hover ${Icon} {
    animation: ${shake} 0.75s linear 0s infinite;
  }
  @media (max-width: 600px) {
    margin-bottom: 10vw;
  }
`;

export const Row = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 80%;
  flex-direction: column;
  @media (min-width: 600px) {
    flex-direction: row;
    width: 75%;

    align-items: flex-start;
  }
  .dash-one {
    position: absolute;
    @media (max-width: 599px) {
      display: none;
    }
    @media (min-width: 600px) and (max-width: 999px) {
      left: 14.75vw;
      width: 7.25vw;
      top: 6vw;
    }
    @media (min-width: 1000px) {
      left: 14.25vw;
      width: 8vw;
      top: 6vw;
    }
  }
  .dash-two {
    position: absolute;
    @media (max-width: 599px) {
      display: none;
    }
    @media (min-width: 600px) and (max-width: 999px) {
      left: 32.75vw;
      width: 7.75vw;
      top: 4vw;
    }
    @media (min-width: 1000px) {
      left: 33vw;
      width: 8vw;
      top: 4vw;
    }
  }
  .dash-three {
    position: absolute;
    @media (max-width: 599px) {
      display: none;
    }
    @media (min-width: 600px) and (max-width: 999px) {
      left: 51.25vw;
      width: 7.25vw;
      top: 6vw;
    }
    @media (min-width: 1000px) {
      left: 51.5vw;
      width: 8vw;
      top: 6vw;
    }
  }
`;
export const Title = styled.h3`
  padding: 0rem 0.3rem 0rem 0.3rem;
  font-size: 6.5vw;
  text-align: center;
  color: ${(props) => props.color ?? '#1c2253'};
  @media only screen and (min-width: 600px) {
    font-size: 2.75vw;
    display: flex;
    flex-direction: column;
  }
  @media only screen and (max-width: 600px) {
    span {
      margin-left: 2vw;
    }
  }
`;

export const Circle = styled.div`
  border: 4vw solid ${(props) => props.color};
  border-radius: 50%;
  @media (min-width: 600px) {
    border: 1vw solid ${(props) => props.color};
  }
`;
export const IconCircle = styled.div`
  display: flex;
  justify-content: center;
  padding: 2vw;
  align-items: center;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  overflow: hidden;
  @media (min-width: 600px) {
    padding: 1vw;
  }
`;
export const ThinCircle = styled(Circle)`
  border-width: 0.75vw;
  @media (min-width: 600px) {
    border: 0.25vw solid ${(props) => props.color};
  }
`;
export const BoldText = styled.h4`
  font-size: 5.9vw;
  text-align: center;
  line-height: 130%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: #0b163f;
  padding: 1rem;
  width: calc(100% - 1rem);
  br {
    display: none;
  }
  @media (min-width: 600px) {
    padding: 0.5vw 0vw;
    font-size: 1.75vw;
    width: calc(100%);
    min-height: 6vw;
    br {
      display: none;
    }
  }
  @media (min-width: 1000px) {
    font-size: 1.25vw;
    br {
      display: block;
    }
  }
`;

export const SubText = styled.p`
  font-size: 4.3vw;
  line-height: 162%;
  text-align: center;
  font-weight: 500;
  color: #3f3c5c;
  @media (min-width: 600px) {
    font-size: 1.75vw;
    line-height: 150%;
  }
  @media (min-width: 1000px) {
    font-size: 1vw;
    line-height: 178%;
  }
`;

export const ArrowIcon = styled.img`
  padding: 0.5rem;
  background-color: white;
  border-radius: 50%;
`;
export const TRUText = styled.p`
  text-decoration: none;
  color: rgb(25, 193, 243);
  padding: 0.25rem;
  color: #1c2253;
  font-size: 17px;
`;
