/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
import React, { useEffect, useLayoutEffect, useState, Suspense, lazy } from 'react';
import { useLocation } from 'react-router-dom';
import StyledApp from './styles/StyledApp.js';
import GlobalStyle from './styles/GlobalStyle.js';
import { Info } from './Layout/Info/Info.js';
import { Offer } from './Layout/Offer/Offer.js';
import { Steps } from './Layout/Steps/Steps.js';
import { Header } from './Layout/Header/Header.js';
import { Welcome } from './Layout/Welcome/Welcome.js';
import Testimonials from './Layout/Testimonials/index.js';
import Loader from 'components/Loader.js';
import KeySteps from 'Layout/KeySteps/index.js';
import Background from 'Layout/Background/index.js';
import Tips from 'Layout/Tips/index.js';
import Footer from 'Layout/Footer/Footer.js';
const FormModal = lazy(() => import('./Layout/FormModal/FormModal.js'));
const Video = lazy(() => import('./Layout/Video/Video.js'));

function HomePage() {
  const location = useLocation();
  const [infoAni, setInfoAni] = useState(false);
  const [offerAni, setOfferAni] = useState(false);
  const [videoToggle, setVideoToggle] = useState(false);
  const [played, setPlayed] = useState(false);
  const [show, setShow] = useState(false);
  const [validate, setValidate] = useState(false);
  const [toggle, setToggle] = useState([]);
  const [loanOfficerData, setLoanOfficerData] = useState(null);
  const [truTermsLink, setTruTermsLink] = useState(process.env.REACT_APP_TRU_TERMS_URL);

  useEffect(() => {
    try {
      setTimeout(() => {
        const ascript = document.createElement('script');
        ascript.src = 'https://d2w24n4g34usfg.cloudfront.net/js/analytics.min.js';
        ascript.async = true;
        document.body.appendChild(ascript);

        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-M23BKJ3');

        (function (d) {
          var s = d.createElement('script');
          s.setAttribute('data-account', 'RF3LDqOACA');
          s.setAttribute('src', 'https://cdn.userway.org/widget.js');
          (d.body || d.head).appendChild(s);
        })(document);
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const queryData = new URLSearchParams(location.search);
    const lof = queryData.get('lof') || queryData.get('rlo') || '';
    const blof = queryData.get('blof') || '';
    if (lof || blof) {
      let lofData = {};
      if (lof) {
        lofData = {
          ssid: lof,
          leadSource: "SELF"
        };
        setTruTermsLink(truTermsLink + "?rlo=" + lof);
      }
      if (blof) {
        lofData = {
          ssid: blof,
          leadSource: "SELFGEN"
        };
        setTruTermsLink(truTermsLink + "?blof=" + blof);
      }
      setLoanOfficerData(lofData);
    }
  }, []);

  useLayoutEffect(() => {
    const docViewTop = window.scrollY;
    const docViewBottom = docViewTop + window.innerHeight;
    const info = document.getElementById('info');
    if (info.offsetTop <= docViewBottom && info.offsetTop >= docViewTop) {
      setInfoAni(true);
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onScroll = () => {
    const docViewTop = window.scrollY;
    const docViewBottom = docViewTop + window.innerHeight;
    const info = document.getElementById('info');
    const offer = document.getElementById('offer');
    if (info.offsetTop <= docViewBottom && info.offsetTop >= docViewTop) {
      setInfoAni(true);
    }
    if (offer.offsetTop <= docViewBottom && offer.offsetTop >= docViewTop) {
      setOfferAni(true);
    }
  };

  const handleVideo = (videoToggle) => {
    setVideoToggle(!videoToggle);
    setPlayed(true);
  };
  const handleClick = () => {
    setValidate((validate) => (validate = !validate));
    setShow((show) => (show = !show));
  };

  const years = () => {
    // Set the time component of the founding date to midnight
    let founding = new Date('Aug 12, 1980 00:00:00');
    let today = new Date();
    // Calculate the difference in years
    let differenceInMilliseconds = today - founding;
    let years = Math.floor(differenceInMilliseconds / (365.25 * 24 * 60 * 60 * 1000));
    return years;
  };
  const currentYear = new Date().getFullYear();

  return (
    <>
      <StyledApp className="">
        <Background />
        <GlobalStyle />
        <Header loanOfficerData={loanOfficerData} />
        <Welcome handleVideo={handleVideo} videoToggle={videoToggle} handleClick={handleClick} truTermsLink={truTermsLink} />
        <Info years={years} infoAni={infoAni} />
        <Steps handleClick={handleClick} />
        {process.env.REACT_APP_LANG !== 'es' && <Tips />}
        <Offer offerAni={offerAni} years={years} handleClick={handleClick} truTermsLink={truTermsLink} />
        <KeySteps />
        <Testimonials />
        <Suspense fallback={<Loader />}>
          {show && <FormModal show={show} buttonClicked={handleClick} validate={validate} loanOfficerData={loanOfficerData} />}
        </Suspense>
        <Suspense fallback={<Loader />}>
          {played && <Video handleVideo={handleVideo} videoToggle={videoToggle} />}
        </Suspense>
      </StyledApp>
      <Footer year={currentYear} loanOfficerData={loanOfficerData} />
    </>
  );
}

export default HomePage;
