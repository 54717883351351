import React from 'react';
import './index.css';

const Background = () => {
  return (
    <>
      <div className="ellipse-nexthome-left"></div>
      <div className="ellipse-nexthome-right"></div>
      <div className="ellipse-keysteps"></div>
      <div className="ellipse-faq"></div>
    </>
  );
};

export default Background;
