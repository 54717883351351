import React from 'react';
import styled, { keyframes } from 'styled-components';
import add from 'assets/addIcon.svg';
import minus from 'assets/minus-icon.svg';
export const FaqWidget = ({ toggle, idx, handleToggle, title, subtitle }) => (
  <WidgetBox onClick={() => handleToggle(idx, toggle)} className="faqItem">
    <Row>
      <Title toggle={toggle[idx]}>{title}</Title>
      {toggle[idx] ? <Minus src={minus} /> : <Plus src={add} />}
    </Row>
    {toggle[idx] && <Subtext>{subtitle}</Subtext>}
  </WidgetBox>
);

const WidgetBox = styled.span`
  width: calc(100% - 1vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #f9fafa;
  cursor: pointer;
  margin: 1vw;
  @media only screen and (min-width: 600px) {
    margin: 0.5vw;
  }
`;

const Title = styled.h4`
  color: ${(props) => (props.toggle ? '#19C1F3' : '#1C2253')};
  font-size: 3.24vw;
  padding: 2vw 1vw;
  margin-top: 0.5vw;
  @media (min-width: 600px) {
    font-size: 1.25vw;
    padding: 1vw 1vw;
  }
  @media (min-width: 1000px) {
    font-size: 1vw;
    padding: 1vw 1vw;
  }
`;
const Row = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  width: calc(100%);
  padding: 0vw 1vw;
`;
export const fade = keyframes`
0%{
opacity:0.1;
}
100%{
opacity:1;
}
`;
const Plus = styled.img`
  height: 3vw;
  animation: ${fade} 0.2s linear 0s 1;
  @media (min-width: 600px) {
    height: 1.75vw;
  }
  @media (min-width: 1000px) {
    height: 1.25vw;
  }
`;
const Minus = styled(Plus)`
  animation: ${fade} 0.2s linear 0s 1;
`;
const open = keyframes`
from{transform:translateY(-40%);opacity:0;}
to{transform:translateY(0%);opacity:1;}
`;

const Subtext = styled.p`
  font-size: 2.75vw;
  color: #5c5e70;
  width: calc(100% - 6vw);
  padding: 1vw 3vw 1vw 3vw;
  line-height: 162%;
  overflow: hidden;
  animation: ${open} 0.15s ease-out 0s 1 forwards;
  @media (min-width: 600px) {
    font-size: 1.25vw;
    width: calc(100%);
    font-weight: 600;
    padding: 0vw 2vw 0vw 2vw;
  }
  @media (min-width: 1000px) {
    font-size: 1vw;
    width: calc(100%);
    padding: 0vw 2vw 0vw 2vw;
  }
`;
